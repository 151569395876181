<template>
  <div class="presentation">
    <div class="presentation-wrap">
      <div class="top">
        <img :src="item" alt="" v-for="(item, index) in baseImgUri" :key="index">
      </div>
      <div class="bottom">
        <div v-for="(item, index) in itemContent" :key="index" :class="item.className" @click="toggleTab(index)">
          <div class="item-title">
            <div class="chinese-title">
              <img :src="item.isClick ? item.iconWhite : item.iconBlue " alt="">
              {{item.title}}
            </div>
            <div class="english-title">{{item.englishTitle}}</div>
          </div>
          <div class="item-content">{{item.content}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Presentation',
  data() {
    return {
      baseImgUri: [require('@assets/img/rainbowchalk/base_img1.png'), require('@assets/img/rainbowchalk/base_img2.png'), require('@assets/img/rainbowchalk/base_img3.png')],
      teamImgUri: ['', '', ''],
      itemContent: [
        {isClick: true, className: 'science-base isActive', iconBlue: require('@assets/img/rainbowchalk/base_icon_blue.png'), iconWhite: require('@assets/img/rainbowchalk/base_icon_white.png'), title: '科普基地', englishTitle: 'Popular Science Base', content: '朝阳气象局科普馆、南郊观象台、密云云岭上物候观测基地、门头沟科普馆、上甸子大气本地观测站、中国气象科技展馆。'},
        {isClick: false, className: 'science-team',  iconBlue: require('@assets/img/rainbowchalk/team_icon_blue.png'), iconWhite: require('@assets/img/rainbowchalk/team_icon_white.png'), title: '科普团队', englishTitle: 'Science Team', content: '国家级气象领域的专家和培训师，具有10年以上的科研或教育经验.'},
      ],
      
    }
  },
  methods: {
    // 切换tab
    toggleTab(index) {
      if (!this.itemContent[index].isClick) {
        this.itemContent[index].isClick = true
        this.itemContent[index].className = this.itemContent[index].className + ' isActive'
        this.baseImgUri = [require('@assets/img/rainbowchalk/base_img1.png'), require('@assets/img/rainbowchalk/base_img2.png'), require('@assets/img/rainbowchalk/base_img3.png')]

        if (index == 0) {
          this.itemContent[index + 1].isClick = false
          this.itemContent[index + 1].className = 'science-team'
        } else {
          this.itemContent[index - 1].isClick = false
          this.itemContent[index - 1].className = 'science-base'
          this.baseImgUri = [require('@assets/img/rainbowchalk/base_img2.png'), require('@assets/img/rainbowchalk/base_img2.png'), require('@assets/img/rainbowchalk/base_img2.png')]
        }
      }

    }
  }
}
</script>

<style lang="scss" scoped>
  .presentation {
    width: 100%;
    height: 810px;
    padding-top: 135px;
    background-color: #1b283a;
    .presentation-wrap {
      width: 1206px;
      height: 468px;
      margin: 0 auto;
      .top {
        height: 317px;
        background-color: #fff;
        border-top-right-radius: 18px;
        border-top-left-radius: 18px;
        overflow: hidden;
        img {
          display: block;
          float: left;
          &:nth-of-type(-n+2) {
            padding-right: 3px;
          }
        }
      }
      .bottom {
        width: 100%;
        height: 150px;
        .science-base,
        .science-team {
          // width: 603px;
          float: left;
          width: 50%;
          height: 100%;
          padding: 30px 47px;
          background-color: #1b283a;
          border: solid 2px #00b7ee;
          cursor: pointer;
          .item-title {
            margin-bottom: 10px;
            .chinese-title {
              position: relative;
              padding-left: 32px;
              font-size: 20px;
              img {
                display: block;
                position: absolute;
                top: 4px;
                left: 200px;
                width: 23px;
                height: 21px;
              }
            }
            .english-title {
              font-size: 12px;
              padding-left: 0;
            }
          }
          .item-content {
            font-size: 12px;
            text-align: left;
            text-indent: 28px;
          }
        }
        .science-base {
          border-bottom-left-radius: 10px;
          border-right-width: 1px;
        }
        .science-team {
          border-bottom-right-radius: 10px;
          border-left-width: 1px;
        }

        .isActive {
          background-color: #5696ff;
          border: 0;
        }
      }
    }
  }
</style>